import React from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import sendGAEvent from "utils/sendGAEvent";
import { getCreatorUrl } from "utils/url/creatorUrls";

import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  guest: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
    width: "100%",
    overflow: "hidden",
    flexWrap: "wrap",
  },
  text: {
    ...gStyles.Regular,
    fontSize: ".813rem",
    lineHeight: "16px",
    display: "block",
    color: colours.darkGrey,
    margin: 0,
    width: "max-content",
    textWrap: "noWrap",

    ":hover": {
      color: colours.primary,
    },
  },
  bottomRowContainer: {
    display: "grid",
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    maxWidth: "100%",
    alignItems: "start",

    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
};

function PodcastCardGuests(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId } = props;

  const podcast = usePodcastData(podcastId);
  const onCategoryClick = (guest) => {
    sendGAEvent({
      action: "PodcastCardGuestItemClick",
      podcast_id: podcastId,
      creator_name: guest?.get("name"),
      creator_id: guest?.get("id"),
    });
  };

  const guests = podcast?.get("latest_guests");

  if (!guests || guests?.size === 0) {
    return null;
  }

  return (
    <div className={css(styles.bottomRowContainer, styles.bottomAlign)}>
      <h3 className={css(styles.bottomRowLabel)}>Recent Guests</h3>
      <section className={css(styles.guest)}>
        {guests?.valueSeq()?.map((guest, index) => {
          const creatorLink = getCreatorUrl(guest);

          return (
            <BasicTooltip
              renderTooltip={() => guest?.get("name")}
              key={guest?.get("name")}
            >
              {(tooltipProps) => (
                <Link
                  {...tooltipProps}
                  key={guest?.get("id")}
                  to={creatorLink}
                  className={css(styles.text)}
                  onClick={() => onCategoryClick(guest)}
                >
                  {`${guest?.get("name")}${
                    index === guests?.size - 1 ? "" : ","
                  }`}
                </Link>
              )}
            </BasicTooltip>
          );
        })}
      </section>
    </div>
  );
}

export default PodcastCardGuests;
