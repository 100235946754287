import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import AddToList from "components/Common/table/commons/AddToList";
import CardFollowAction from "components/Common/table/commons/CardFollowAction";
import CardRateAction from "components/Common/table/commons/CardRateAction";
import RemoveFromListButton from "components/Common/table/commons/RemoveFromListButton";
import ShowMoreContainerWithHighlight from "components/Common/table/commons/ShowMoreContainerWithHighlight";
import HighLightText from "components/Highlight/HighLightText";

import EllipsisAction from "../CardActions/EllipsisAction";
import EntityCardImage from "../EntityCardImage";
import NewCardTitle from "../NewCardTitle";
import PodcastCardAudience from "../PodcastCardAudience";
import PodcastCardCategories from "../PodcastCardCategories";
import PodcastCardContacts from "../PodcastCardContacts";
import PodcastCardGuests from "../PodcastCardGuests";
import PodcastCardHosts from "../PodcastCardHosts";
import PodcastCardNetworkAndAuthor from "../PodcastCardNetworkAndAuthor";
import PodcastCardSimilarPodcasts from "../PodcastCardSimilarPodcasts";
import PodcastCardStats from "../PodcastCardStats";

import ratingActionCreators from "actions/rating";
import { PODCAST_CARD_HEIGHT_DESKTOP } from "constants/podcast";
import getPodcastHasGuests from "utils/entity/getPodcastHasGuests";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import cardStyles from "styles/CardStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  podcastDesktopCard: {
    ...cardStyles.mobileCard,
    minHeight: "13rem",
    padding: "1.5rem 1rem",
    border: `1px solid var(--color-neutral-d85)`,
    borderRadius: "0.75rem",

    [ScreenSizes.mdAndAbove]: {
      ...cardStyles.desktopCard,
      padding: "1.5rem",
      maxWidth: "54rem",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: 8,
    },
  },
  middleRow: {
    ...cardStyles.topRow,
    borderBottom: "none",
    display: "flex",
    flexDirection: "column",
    margin: "1rem 0",
    paddingBottom: 0,

    [ScreenSizes.lgAndAbove]: {
      borderBottom: "none",
    },
  },
  topFirstRow: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gap: "1rem",
    marginBottom: "1rem",
  },
  topFirstRowCol1: {
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
    gap: "0.313rem",
    alignContent: "space-between",
  },
  description: {
    ...cardStyles.desktopCardDescription,
    lineHeight: 1.5,
  },
  bottomRow: {
    ...cardStyles.bottomRow,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1rem",
    alignItems: "center",

    [createBreakpoint({ min: 1115 })]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  bottomAlign: {
    alignItems: "start",
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
  bottomRowInsidePanel: {
    ...cardStyles.bottomRowInsidePanel,
  },
  actions: {
    ...cardStyles.cardActions,
  },
  shareContainer: {
    ...cardStyles.desktopShareContainer,
  },

  topActions: {
    display: "none",

    [createBreakpoint({ min: 1115 })]: {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      height: "2rem",
      width: "100%",
    },
  },
  bottomActions: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    height: "max-content",
    width: "100%",
    marginTop: "1rem",
    paddingTop: "1.5rem",
    borderTop: `1px solid ${colours.borderGrey}`,

    [createBreakpoint({ min: 1115 })]: {
      display: "none",
    },
  },
  largeOnly: {
    display: "none",

    [createBreakpoint({ min: 1115 })]: {
      display: "grid",
    },
  },
  smallOnly: {
    display: "grid",

    [createBreakpoint({ min: 1115 })]: {
      display: "none",
    },
  },
};

const insidePanelStyles = {
  podcastDesktopCard: {
    ...cardStyles.noRaisedCard,
    ...cardStyles.flatCard,
  },
};

const noPanelStyles = {
  podcastDesktopCard: {
    ...cardStyles.cardNoPanel,
  },
  topRow: {
    paddingBottom: 0,
    marginBottom: 0,
    borderBottom: 0,
    [ScreenSizes.lgAndAbove]: {
      paddingBottom: 0,
      marginBottom: 0,
      borderBottom: 0,
    },
  },
};

const ellipsisStyles = {
  actionButtonsContainer: {
    borderRadius: "25px",
  },
  ellipsisButtonInner: {
    height: "2rem",
    width: "2rem",
    borderRadius: "25px",
    border: `1px solid ${colours.borderGrey}`,
  },
  buttonContainer: {
    marginRight: 0,
    marginLeft: "auto",

    [createBreakpoint({ min: 1115 })]: {
      marginLeft: 0,
    },
  },
};

const entityImageStyles = {
  entityImage: {
    height: "4.563rem",
    minWidth: "4.563rem",
    width: "4.563rem",
  },
};

const ellipsisActionStyles = {
  onList: {
    background: "var(--color-primary-d10)",
    border: "none",
  },
  full: {
    padding: "0 0.6rem",
    borderRadius: 25,
    border: `1px solid ${colours.borderGrey}`,
  },
  addToListIcon: {
    padding: "0 0.6rem",
    borderRadius: 25,

    width: "max-content",
    height: "2rem",
  },
};

const passedEllipsisMenuItems = [
  "view",
  "list",
  "rate",
  "share",
  "similar",
  "credits",
  "follow",
];

const PodcastDesktopCard = (props) => {
  const {
    podcast,
    hideBottomRow,
    noPanel,
    insidePanel,
    renderEntityImageOverlay,
    renderOnMount,
    deferCard,
    showRemoveItem,
    withLabel,
    listId,
    principalSearchTerm,
    secondarySearchTerm,
  } = props;

  const { styles } = useStyles(
    [baseStyles, noPanel && noPanelStyles, insidePanel && insidePanelStyles],
    props
  );
  const podcastUrl = getPodcastUrl(podcast);
  const canRenderGuests = getPodcastHasGuests(podcast);

  const hasPro = useUserHasPro();

  const { openPodcastRatingModal } = useActionCreators(ratingActionCreators);

  const onRateButtonClick = () =>
    openPodcastRatingModal(podcast?.get("id"), { entity: podcast });

  const renderTopRow = () => (
    <div className={css(styles.topFirstRow)}>
      <EntityCardImage
        entity={podcast}
        entityType="podcast"
        entityUrl={podcastUrl}
        renderEntityImageOverlay={renderEntityImageOverlay}
        renderOnMount={renderOnMount}
        styles={entityImageStyles}
      />
      <div className={css(styles.topFirstRowCol1)}>
        <NewCardTitle
          entity={podcast}
          entityType="podcast"
          badgeSize={13}
          renderOnMount={renderOnMount}
          title={
            <HighLightText
              textToHighlight={podcast?.get("title")}
              principalSearchTerm={principalSearchTerm}
              secondarySearchTerm={secondarySearchTerm}
            />
          }
          entityUrl={hasPro ? `${podcastUrl}/insights` : podcastUrl}
        />
        <PodcastCardNetworkAndAuthor podcast={podcast} />
        <PodcastCardStats podcast={podcast} renderOnMount={renderOnMount} />
      </div>
      <div className={css(styles.topActions)}>
        <CardFollowAction entity={podcast} entity_type="podcast" />
        <CardRateAction entity={podcast} entity_type="podcast" />
        <AddToList
          entity={podcast}
          entityId={podcast?.get("id")}
          entityType={"podcast"}
          withLabel={withLabel}
          styles={ellipsisActionStyles}
        />
        {showRemoveItem && (
          <RemoveFromListButton
            listId={listId}
            entityType="podcast"
            entityId={podcast?.get("id")}
          />
        )}
        <EllipsisAction
          entity={podcast}
          entity_type={"podcast"}
          id="ellipsis"
          podcast={podcast}
          showShare={true}
          styles={ellipsisStyles}
          passedEllipsisButtonStyles={ellipsisStyles}
          action={{ showEllipsisItems: passedEllipsisMenuItems }}
          closeOnOutsideclick
        />
      </div>
    </div>
  );

  const renderMiddleRow = () => (
    <div className={css(styles.middleRow)}>
      <PodcastCardHosts podcastId={podcast?.get("id")} />
      <ShowMoreContainerWithHighlight
        description={podcast.get("description")}
        entity={podcast}
        entityType="Podcast"
        principalSearchTerm={principalSearchTerm}
        secondarySearchTerm={secondarySearchTerm}
      />
    </div>
  );

  const renderBottomRow = () => (
    <>
      <div
        className={css(
          styles.bottomRow,
          insidePanel && styles.bottomRowInsidePanel
        )}
      >
        {hasPro && (
          <PodcastCardCategories
            podcastId={podcast?.get("id")}
            context={"PodcastCardCategoryPills"}
          />
        )}
        <PodcastCardAudience podcastId={podcast?.get("id")} />
        {!hasPro && <PodcastCardContacts />}
        <PodcastCardSimilarPodcasts podcastId={podcast?.get("id")} />
        {canRenderGuests && (
          <PodcastCardGuests podcastId={podcast?.get("id")} />
        )}
      </div>
      <div className={css(styles.bottomActions)}>
        <AddToList
          entity={podcast}
          entityId={podcast?.get("id")}
          entityType={"podcast"}
          withLabel={withLabel}
          styles={ellipsisActionStyles}
        />
        <CardFollowAction entity={podcast} entity_type="podcast" />
        <span className={css(styles.ellipsisbutton)}>
          <CardRateAction
            entity={podcast}
            entity_type="podcast"
            onRateButtonClick={onRateButtonClick}
          />
        </span>
        {showRemoveItem && (
          <RemoveFromListButton
            listId={listId}
            entityType="podcast"
            entityId={podcast?.get("id")}
          />
        )}
        <EllipsisAction
          entity={podcast}
          entity_type={"podcast"}
          id="ellipsis"
          podcast={podcast}
          showShare={true}
          styles={ellipsisStyles}
          action={{ showEllipsisItems: passedEllipsisMenuItems }}
          closeOnOutsideclick
        />
      </div>
    </>
  );

  const renderCard = () => (
    <div
      data-id="podcast-desktop-card"
      className={css(
        styles.podcastDesktopCard,
        noPanel && styles.podcastDesktopCardNoPanel,
        styles.entityCard
      )}
    >
      {renderTopRow()}
      {renderMiddleRow()}
      {!hideBottomRow && renderBottomRow()}
    </div>
  );

  if (deferCard) {
    return (
      <LazyLoadComponent height={PODCAST_CARD_HEIGHT_DESKTOP}>
        {renderCard()}
      </LazyLoadComponent>
    );
  }

  return renderCard();
};

PodcastDesktopCard.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  hideBottomRow: PropTypes.bool,
  noPanel: PropTypes.bool,
  insidePanel: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
  renderOnMount: PropTypes.bool,
  deferCard: PropTypes.bool,
  withLabel: PropTypes.bool,
};

PodcastDesktopCard.defaultProps = {
  podcast: null,
  hideBottomRow: false,
  noPanel: false,
  insidePanel: false,
  renderEntityImageOverlay: null,
  renderOnMount: false,
  withLabel: true,
};

export default PodcastDesktopCard;
