import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";

import ProCircleIcon from "components/Pro/ProCircleIcon";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  lock: {
    ...gStyles.fontRegular,
    fontSize: 13,
    color: colours.secondary,
    outline: "none",
    border: "none",
    display: "flex",
    gap: 4,
    alignItems: "center",
    textWrap: "wrap",
    padding: 0,

    ":hover": {
      cursor: "pointer",
    },
  },
  bottomRowContainer: {
    display: "none",
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",

    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
      display: "grid",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
};

function PodcastCardContacts(props) {
  const { styles, css } = useStyles(baseStyles, props);

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: `podcastCardUnlockReachButtonClicked`,
      entity_type: "Podcasts",
      context: "ProLeadForm",
    });
  }, []);

  const handleClick = () => {
    sendGAEvent({
      action: "openedProLeadFormModal",
      entity_type: "Podcast",
      context: "ProLeadForm",
    });

    showModal("proLeadForm", {
      onDemoRequestSubmitted,
    });
  };

  return (
    <div className={css(styles.bottomRowContainer, styles.largeOnly)}>
      <h3 className={css(styles.bottomRowLabel)}>
        Contacts
        <ProCircleIcon
          width=".5rem"
          height=".5rem"
          top="4px"
          right="auto"
          tooltipPlacement="bottom"
          tooltip="Pro Only"
          thin
          withTooltip
        />
      </h3>
      <button className={css(styles.lock)} onClick={handleClick}>
        <FontAwesomeIcon icon={faLock} dataid="lock" />
        Contacts information
      </button>
    </div>
  );
}

export default PodcastCardContacts;
