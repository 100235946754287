import PropTypes from "prop-types";

import HighLightText from "components/Highlight/HighLightText";

import ShowMoreContainer from "./ShowMoreContainer";

const ShowMoreContainerWithHighlight = (props) => {
  const {
    description,
    showChar = 320,
    entityType,
    entity,
    principalSearchTerm,
    secondarySearchTerm,
    visibleContentText = description?.substr(0, showChar),
  } = props;

  const visibleContentNode = (
    <HighLightText
      textToHighlight={visibleContentText}
      principalSearchTerm={principalSearchTerm}
      secondarySearchTerm={secondarySearchTerm}
    />
  );

  const hiddenText = description?.substr(showChar);
  const hiddenContent = hiddenText && (
    <HighLightText
      textToHighlight={hiddenText}
      principalSearchTerm={principalSearchTerm}
      secondarySearchTerm={secondarySearchTerm}
    />
  );

  return (
    <ShowMoreContainer
      visibleContent={visibleContentNode}
      hiddenContent={hiddenContent}
      entityType={entityType}
      entity={entity}
    />
  );
};

ShowMoreContainerWithHighlight.propTypes = {
  description: PropTypes.string,
  showChar: PropTypes.number,
};

export default ShowMoreContainerWithHighlight;
