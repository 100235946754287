import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { followEntityPromise, unfollowEntityPromise } from "routines/follow";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { capitalize } from "utils/misc";

import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import faFollow from "styles/icons/faFollow";

const baseStyles = {
  following: {
    background: "var(--color-primary-d10)",
    color: colours.primary,
  },
  follow: {
    color: colours.darkGrey,
    background: colours.neutral,
    border: "none",
    height: "2rem",
    width: "2rem",
    minWidth: "2rem",
    borderRadius: "50%",
    fontSize: "1rem",

    ":hover": {
      cursor: "pointer",
    },
  },
};

const CardFollowAction = (props) => {
  const { entity, entity_type, allowFollow } = props;
  const { styles, css } = useStyles(baseStyles, props);

  const { followEntity, unfollowEntity } = useRoutinePromises({
    followEntity: followEntityPromise,
    unfollowEntity: unfollowEntityPromise,
  });

  const following = entity && entity.getIn(["user_data", "follows"]);
  const submitting = entity && entity.getIn(["user_data", "follow_saving"]);

  const handleFollowClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const fn = following ? unfollowEntity : followEntity;

      fn({
        entity_type,
        entity_id: entity.get("id"),
        entity,
      });
    },
    [following, unfollowEntity, followEntity, entity_type, entity]
  );

  if (!following && !allowFollow) {
    return null;
  }

  return (
    <BasicTooltip
      renderTooltip={() =>
        `${following ? "Unfollow" : "Follow"} this ${capitalize(entity_type)}`
      }
    >
      {(tooltipProps) => (
        <button
          data-testid="addToList"
          className={css(styles.follow, following && styles.following)}
          {...tooltipProps}
          onClick={handleFollowClick}
        >
          <FontAwesomeIcon
            icon={submitting ? faCircleNotch : faFollow}
            dataid={submitting ? "loading" : "following"}
          />
        </button>
      )}
    </BasicTooltip>
  );
};

CardFollowAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  allowFollow: PropTypes.bool,
};

CardFollowAction.defaultProps = {
  entity: null,
  entity_type: null,
  allowFollow: false,
};

export default memo(CardFollowAction);
