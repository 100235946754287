import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import EntityCardImage from "./EntityCardImage";

import getPodcastUrl from "utils/entity/getPodcastUrl";

import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  similar: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
  },
  button: {
    height: "1.5rem",
    width: "1.5rem",
    display: "grid",
    placeItems: "center",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: "0.25rem",
    fontSize: "0.813rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  bottomRowContainer: {
    display: "none",
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",

    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
      display: "grid",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
  skeletonLoadingImage: {
    ...gStyles.skeletonPlaceholder,

    height: "1.5rem",
    width: "1.5rem",
  },
};

const imageStyles = {
  entityImage: {
    ...cardStyles.mobileCompactCardEntityImage,
    background: "none",
    marginRight: 0,

    [ScreenSizes.lgAndAbove]: {
      ...cardStyles.desktopCardEntityImage,
      height: "1.5rem",
      minWidth: "1.5rem",
      width: "1.5rem",
      marginRight: 0,
      background: "none",
    },
  },
};

function PodcastImageContainer({ podcastId }) {
  const similarPodcast = usePodcastData(podcastId);

  if (similarPodcast) {
    return (
      <EntityCardImage
        entity={similarPodcast}
        entityType="podcast"
        renderOnMount={true}
        styles={imageStyles}
      />
    );
  }

  return null;
}

function PodcastCardSimilarPodcasts(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId } = props;

  const podcast = usePodcastData(podcastId);
  const podcastUrl = getPodcastUrl(podcast);
  const allSimilarPodcast =
    podcast?.get("similar_podcasts_details") ||
    podcast?.get("similar_podcasts");
  const similarPodcasts = allSimilarPodcast?.slice(0, 6);

  const viewMoreIcon = (
    <BasicTooltip renderTooltip={() => "View More"}>
      {(tooltipProps) => (
        <Link
          {...tooltipProps}
          target="_blank"
          to={`${podcastUrl}/similar`}
          className={css(styles.button)}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      )}
    </BasicTooltip>
  );

  if (!similarPodcasts || similarPodcasts?.size === 0) {
    return null;
  }

  return (
    <div className={css(styles.bottomRowContainer)}>
      <h3 className={css(styles.bottomRowLabel)}>Similar</h3>
      <section className={css(styles.similar)}>
        {similarPodcasts?.map((similarPodcast) => {
          if (typeof similarPodcast !== "object") {
            return (
              <PodcastImageContainer
                key={similarPodcast}
                podcastId={similarPodcast}
              />
            );
          }

          return (
            <EntityCardImage
              key={similarPodcast?.get("id")}
              entity={similarPodcast}
              entityType="podcast"
              renderOnMount={true}
              styles={imageStyles}
            />
          );
        })}
        {viewMoreIcon}
      </section>
    </div>
  );
}

export default PodcastCardSimilarPodcasts;
