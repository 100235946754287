import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import ClaimedBadge from "../../Podcast/ClaimedBadge";
import PodcastProfileHoverPopover from "../../Profiles/PodcastProfileHoverPopover";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";

const baseStyles = {
  titleContainer: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    flexDirection: "row",
  },
  title: {
    ...cardStyles.mobileCardPrimaryTitle,
    marginBottom: 0,
    fontSize: 20,
    lineHeight: "22px",
  },
};

const claimStyles = {
  tooltip: {
    marginLeft: 0,
    marginTop: 0,
  },
};

const NewCardTitle = (props) => {
  const { entity, badgeSize, title, entityUrl, analyticalProps } = props;
  const { styles } = useStyles(baseStyles, props);

  const isClaimed = entity?.get("is_claimed");

  const handleClick = useCallback(() => {
    sendGAEvent({
      action: "titleClicked",
      entityId: entity?.get("id"),
      entity_name: entity?.get("title"),
      context: "Title Click",
      ...analyticalProps,
    });
  }, [analyticalProps, entity]);

  return (
    <PodcastProfileHoverPopover entity_id={entity?.get("id")}>
      <span className={css(styles.titleContainer)} onClick={handleClick}>
        <Link className={css(styles.title)} to={entityUrl}>
          {title}
        </Link>

        {isClaimed && (
          <ClaimedBadge
            key="claimedBadge"
            showLabel={false}
            size={badgeSize}
            showTooltip
            styles={claimStyles}
          />
        )}
      </span>
    </PodcastProfileHoverPopover>
  );
};

NewCardTitle.propTypes = {
  entity: PropTypes.object,
  badgeSize: PropTypes.number,
};

NewCardTitle.defaultProps = {
  clamp: 2,
};

export default memo(NewCardTitle);
