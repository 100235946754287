import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";

import CardActions from "../../../Entities/Cards/CardActions";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  rated: {
    background: "var(--color-primary-d10)",
    border: "none",
    color: colours.primary,
    height: "2rem",
    width: "2rem",
    borderRadius: "50%",
    ...gStyles.avalonBold,
    fontSize: "0.75rem",

    ":hover": {
      cursor: "pointer",
    },
  },
};

const ACTIONS = [{ type: "rate" }];

const CardRateAction = (props) => {
  const { entity, entity_type } = props;
  const { styles, css } = useStyles(baseStyles, props);

  const rated = entity && entity.getIn(["user_data", "rating", "rating"]);

  if (!rated) {
    return null;
  }

  return (
    <div className={css(styles.rated)}>
      <CardActions
        actions={ACTIONS}
        entity={entity}
        entity_type={entity_type}
      />
    </div>
  );
};

CardRateAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
};

CardRateAction.defaultProps = {
  entity: null,
  entity_type: null,
};

export default memo(CardRateAction);
