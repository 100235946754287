import PropTypes from "prop-types";
import React, { useState } from "react";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  details: {
    ...gStyles.fontRegular,
    fontSize: "0.875em",
    margin: 0,
    lineHeight: 1.5,
  },
  moreLink: {
    color: colours.black,
    ...gStyles.fontBold,
    fontSize: "0.688rem",
    cursor: "pointer",
    marginLeft: "0.25em",
    whiteSpace: "nowrap",
    padding: "0",
    border: "none",
  },
  remainingContent: {
    display: "none",
  },
};

const ShowMoreContainer = (props) => {
  const {
    description,
    showChar = 320,
    entityType,
    entity,
    visibleContent = description?.substr(0, showChar),
    hiddenContent = description?.substr(showChar),
  } = props;
  const { styles, css } = useStyles(baseStyles, props);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleContent = () => {
    sendGAEvent({
      action: isExpanded
        ? `Close${entityType}Description`
        : `Expand${entityType}Description`,
      entity_id: entity?.get("id"),
      entity_name: entity?.get("title"),
      entityType,
    });
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={css(styles.details)}>
      {visibleContent}
      {!isExpanded && hiddenContent && (
        <span>
          <span>...</span>
          <button className={css(styles.moreLink)} onClick={toggleContent}>
            {" "}
            more
          </button>
        </span>
      )}
      {isExpanded && hiddenContent && (
        <span>
          <span>{hiddenContent}</span>
          <button className={css(styles.moreLink)} onClick={toggleContent}>
            {" "}
            less
          </button>
        </span>
      )}
    </div>
  );
};

ShowMoreContainer.propTypes = {
  description: PropTypes.string,
  showChar: PropTypes.number,
};

export default ShowMoreContainer;
